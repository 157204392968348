import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import NavigationBar from './views/NavigationBar.vue';


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const app = createApp(App);

// Globale Komponente registrieren
app.component('navigation-bar', NavigationBar);

// Vuex und Vue Router verwenden
app.use(store).use(router);

// Vue Anwendung montieren
app.mount('#app');