<template>
  <div>
    <navigation-bar></navigation-bar>
    <div class="container mt-4">
      <h1>Change Log</h1>

      <!-- Bootstrap Table -->
      <table class="table table-bordered table-striped table-sm"> <!-- Add table-sm class for smaller table rows -->
        <thead class="thead-light">
          <tr>
            <th>Change Time</th>
            <th>User</th>
            <th>Operation</th>
            <th>Table Name</th>
            <th>Table ID</th>
            <th>Old Value</th>
            <th>New Value</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in changeLogData" :key="log.id">
            <td>{{ log.change_time }}</td>
            <td>{{ log.user }}</td>
            <td>{{ log.operation }}</td>
            <td>{{ log.table_megn }}</td>
            <td>{{ log.table_id }}</td>
            <td>{{ log.old_value || 'N/A' }}</td>
            <td>{{ log.new_value || 'N/A' }}</td>
            <td>{{ log.description }}</td>
          </tr>
        </tbody>
      </table>

      <div v-if="changeLogData.length === 0" class="alert alert-warning">
        Nincsenek adatok.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axiosInstance from '@/axiosInstance';
import NavigationBar from './NavigationBar.vue';

export default {
  components: {
    'navigation-bar': NavigationBar,
  },
  data() {
    return {
      changeLogData: [],
    };
  },
  computed: {
    ...mapGetters(['getLogParams']),  // Hole logParams aus dem Store
  },
  mounted() {
    this.fetchChangeLog();
  },
  methods: {
    async fetchChangeLog() {
      const postData = {
        user: this.getLogParams.user,
        table_name: this.getLogParams.table_name,
        record_id: this.getLogParams.record_id || 0,
      };

      try {
        const response = await axiosInstance.post('/log/log_list.php', postData);
        if (response.status === 200 && response.data.data) {
          this.changeLogData = response.data.data;
        } else {
          this.changeLogData = [];
        }
      } catch (error) {
        console.error('Fehler bei der API-Anfrage:', error);
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}

.table {
  margin-top: 20px;
  font-size: 0.875rem; /* Kleinere Schriftgröße */
  line-height: 1.2; /* Reduzierter Zeilenabstand */
}

thead th {
  text-align: center;
}

td, th {
  padding: 0.4rem; /* Weniger Padding für kleinere Zellen */
}
</style>
