<template>

<navigation-bar></navigation-bar>
    <!-- Hauptinhalt -->
    <div id="app" class="container mt-5">

    </div>

    <div class="container py-5">

      <h1 class="text-center mb-4">Súgó & Támogatás</h1>
  
      <!-- Accordion für Hilfe-Themen -->
      <div id="helpAccordion">
        <div class="accordion" id="accordionExample">
  
          <!-- Funktion 1: Új rendelés létrehozása -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Hogyan lehet új rendelést létrehozni?
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Az új rendelés létrehozásához kattintson a „Új rendelés” gombra a főmenüben. A megjelenő űrlapot töltse ki a szükséges adatokkal, például a megrendelő, termék, mennyiség és szállítási határidő. Miután minden mezőt kitöltött, kattintson a „Mentés” gombra a rendelés rögzítéséhez.
              </div>
            </div>
          </div>
  
          <!-- Funktion 2: Rendelés szerkesztése -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Hogyan lehet meglévő rendelést szerkeszteni?
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                A meglévő rendelés szerkesztéséhez keresse meg a rendelést a rendeléslista nézetben, majd kattintson a kívánt rendelés sorára. Itt módosíthatja az adatokat, és a „Mentés” gombbal rögzítheti a változtatásokat.
              </div>
            </div>
          </div>
  
          <!-- Funktion 3: Szállítás állapotának frissítése -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Hogyan lehet a szállítás állapotát frissíteni?
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                A szállítás állapotának frissítéséhez válassza ki a rendelést a lista nézetben, majd a jobb oldali menüben kattintson a „Szállítás állapot frissítése” gombra. A megjelenő ablakban adja meg az új állapotot, például „Kiszállítva” vagy „Folyamatban”, és kattintson a „Mentés” gombra.
              </div>
            </div>
          </div>
  
          <!-- Tastenkombinationen -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                Billentyűkombinációk
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul>
                  <li><strong>Ctrl + N</strong>: Új rendelés létrehozása</li>
                  <li><strong>Ctrl + S</strong>: Rendelés mentése</li>
                  <li><strong>Ctrl + F</strong>: Rendelés keresése</li>
                  <li><strong>Esc</strong>: Vissza a főmenübe</li>
                  <li><strong>Alt + ←</strong>: Előző oldalra lépés</li>
                  <li><strong>Alt + →</strong>: Következő oldalra lépés</li>
                </ul>
              </div>
            </div>
          </div>
  
          <!-- Kontakt für Support -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                Hogyan léphetek kapcsolatba a támogatással?
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Ha további kérdése van, vagy technikai támogatásra van szüksége, kérjük, vegye fel velünk a kapcsolatot az alábbi elérhetőségeken:
                <ul>
                  <li>Email: <strong>support@cegnev.hu</strong></li>
                  <li>Telefon: <strong>+36 1 234 5678</strong></li>
                </ul>
                Ügyfélszolgálatunk hétfőtől péntekig 8:00 és 18:00 között elérhető.
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HelpPage',
    data() {
      return {};
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  .accordion-button {
    font-size: 1.2rem;
  }
  
  .accordion-body {
    font-size: 1rem;
  }
  
  .accordion-item {
    margin-bottom: 1rem;
  }
  </style>
  