// src/axiosInstance.js
import axios from 'axios';

// Erstelle eine zentrale Axios-Instanz mit Basiskonfiguration
const axiosInstance = axios.create({
  baseURL: 'https://bogir.hu/V2/api',
  auth: {
    username: 'Admin_2024$$',
    password: 'S3cure+P@ssw0rd2024!'
  },
  headers: {
    'Content-Type': 'application/json'
  }
});

export default axiosInstance;
