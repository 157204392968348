<template>
  <div>
    <navigation-bar></navigation-bar>

    <div>
      <h3>{{ viszonylat }} -  {{ cikk }}</h3>
    <p class="small-spacing">{{ megrendelo }}</p>
    <p class="small-spacing">Dátum: {{ datum }}  / szállítási határidő: {{ szall_hatarido }}</p>
    <p class="small-spacing">Hivatkozási szám: {{ hivatkozasi_szam }} </p>
    <p class="small-spacing">Megjegyzés: {{ fo_megjegyzes }} </p>
  </div>
    
    <div class="container mt-5">
      <h1>Rendszám rögzítése</h1>
      <form id="orderForm" @submit.prevent="mentes">
  <!-- Rendszám mit Filter und Fetch Button -->
  <div class="form-group row align-items-top">
  <label for="rendszamFilter" class="col-form-label col-sm-2 col-md-1 align-items-center">Rendszám</label>
  <div class="col-sm-3 col-md-2">
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="rendszamFilter" placeholder="Keresés" v-model="rendszamFilter">
      <div class="input-group-append">
        <button type="button" class="btn btn-secondary btn-sm" @click="fetchRendszam">
          <i class="fas fa-sync-alt"></i>
        </button>
        <button type="button" class="btn btn-secondary btn-sm ml-2" @click="fetchSajatRendszam">Saját</button>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <div class="dropdown w-100">
      <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownRendszamButton" data-bs-toggle="dropdown" aria-expanded="false">
        Kiválasztás
      </button>
      <ul class="dropdown-menu w-100" aria-labelledby="dropdownRendszamButton">
        <li v-for="item in rendszamOptions" :key="item.az">
          <a class="dropdown-item" href="#" @click.prevent="selectRendszam(item)">{{ item.mr_rendszam }} ({{ item.f_nev }})</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-sm-3 col-md-3">
    <input type="text" class="form-control" v-model="selectedRendszamMegn" :style="{ fontWeight: 'bold', color: 'darkgreen' }">
  </div>
</div>


        <!-- Pótkocsi -->
        <div class="form-group row align-items-center">
  <label for="selectedPotkocsi" class="col-form-label col-sm-2 col-md-1">Pótkocsi</label>
  <div class="col-sm-10 col-md-3">
    <input type="text" class="form-control" id="selectedPotkocsi" placeholder="Rendszám megadása" v-model="selectedPotkocsi"  :style="{ fontWeight: 'bold', color: 'darkgreen' }">
  </div>
</div>

        <!-- Felségjel -->

        <div class="form-group row align-items-center">
  <label for="selectedFelsegjel" class="col-form-label col-sm-2 col-md-1">Felségjel</label>
  <div class="col-sm-10 col-md-3">
    <input type="text" class="form-control" id="selectedFelsegjel" placeholder="Országkód megadása" v-model="selectedFelsegjel" :style="{ fontWeight: 'bold', color: 'darkgreen' }">
  </div>
</div>

       <!-- Fuvarozó -->
        <div class="form-group row align-items-top">
  <label for="fuvarozoFilter" class="col-form-label col-sm-2 col-md-1 d-flex align-items-center">Fuvarozó</label>
  <div class="col-sm-3 col-md-2">
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="fuvarozoFilter" placeholder="Keresés" v-model="fuvarozoFilter">
      <div class="input-group-append">
        <button type="button" class="btn btn-secondary btn-sm" @click="fetchFuvarozo">
          <i class="fas fa-sync-alt"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3">
    <div class="dropdown w-100">
      <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownFuvarozoButton" data-bs-toggle="dropdown" aria-expanded="false">
        Kiválasztás
      </button>
      <ul class="dropdown-menu w-100" aria-labelledby="dropdownFuvarozoButton">
        <li v-for="item, in fuvarozoOptions" :key="item.az">
          <a class="dropdown-item" href="#" @click.prevent="selectFuvarozo(item)">{{ item.f_nev }} ({{ item.f_varos }})</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
    <input type="text" class="form-control" v-model="selectedFuvarozoNev"  :style="{ fontWeight: 'bold', color: 'darkgreen' }">
  </div>
</div>


        <!-- Felrakás Dátuma -->
        <div class="form-group row align-items-center">
  <label for="felrakasDatum" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Felrakás dátuma</label>
  <div class="col-md-3">
    <input type="date" class="form-control" id="felrakasDatum" v-model="felrakasDatum">
  </div>
</div>

        <!-- Lerakás Dátuma -->
        <div class="form-group row align-items-center">
  <label for="lerakasDatum" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Lerakás dátuma</label>
  <div class="col-md-3">
    <input type="date" class="form-control" id="lerakasDatum" v-model="lerakasDatum">
  </div>
</div>


        <!-- Felvett Súly -->
        <div class="form-group row align-items-center">
  <label for="felvettSuly" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Felvett súly</label>
  <div class="col-md-3">
    <input type="number" class="form-control" id="felvettSuly" step="0.01" v-model="felvettSuly" placeholder="Mennyiség tonnában">
  </div>
</div>


        <!-- Lerakott Súly -->
        <div class="form-group row align-items-center">
  <label for="lerakottSuly" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Lerakott súly</label>
  <div class="col-md-3">
    <input type="number" class="form-control" id="lerakottSuly" step="0.01" v-model="lerakottSuly" placeholder="Mennyiség tonnában">
  </div>
</div>

<!-- megjegyzés -->
<div class="form-group row align-items-center">
  <label for="megjegyzes" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Megjegyzés</label>
  <div class="col-md-7">
    <input type="text" class="form-control" id="megjegyzes"  v-model="megjegyzes" >
  </div>
</div>


        <!-- Buttons -->
        <div class="form-group btn-container">
            <button type="button" class="btn btn-danger me-5" @click="abbrechen">Mégse</button>
            <button type="submit" class="btn btn-success" >Mentés</button>
            
        </div>


      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axiosInstance from '@/axiosInstance';

export default {
  data() {
    return {
       // Beispiel-Datenfelder
       rendszamFilter: '',
      rendszamOptions: [],
      selectedRendszam: '',
      selectedRendszamMegn: '',
      selectedPotkocsi: '',
      selectedFelsegjel: 'H',
      fuvarozoFilter: '',
      fuvarozoOptions: [],
      selectedFuvarozo: '',
      selectedFuvarozoNev: '',
      selectedFuvarozoVaros: '',
      felrakasDatum: '',
      lerakasDatum: '',
      felvettSuly: null,
      lerakottSuly: null,
      rakodasiSzam: '',
      billenesiKod: '',
      megjegyzes:''
      
    };
  },
  computed: {
    ...mapGetters(['getorderData']),
    ...mapGetters(['getUserData']),
    orderData() {
      return this.getorderData || {};
    },
    az() {
      return this.orderData?.az || 'Standard AZ';
    },
    cegaz() {
      return this.orderData?.cegaz || 'Standard Cegaz';
    },
    datum() {
      return this.orderData?.datum || 'Standard Datum';
    },
    viszonylat() {
    return this.orderData?.viszonylat || 'Standard Viszonylat';
  },
  napifo_az() {
      return this.orderData?.napifo_az || '0';
    },
    napimellek_az() {
      return this.orderData?.napimellek_az || '0';
    },
    tipus() {
      return this.orderData?.tipus || 'Standard Tipus';
    },
    megrendelo() {
      return this.orderData?.megrendelo || 'nem ismert';
    },
  cikk() {
    return this.orderData?.cikk || 'nincs megadva';
  },
  szall_hatarido() {
      return this.orderData?.szall_hatarido || 'hiányzik';
    },
    hivatkozasi_szam() {
      return this.orderData?.hivatkozasi_szam || '-';
    },
  fo_megjegyzes() {
      return this.orderData?.fo_megjegyzes || '-';
    },
  oldal() {
      return this.orderData?.oldal || 'Standard Oldal';
    },
  fsz() {
            return this.getUserData ? this.getUserData.fsz : null;
        },
  ceg_az() {
            return this.getUserData ? this.getUserData.ceg_az : null;
        }
  },
  methods: {

    abbrechen() {
                  if (confirm("Valóban be akarja bejezni az adatmegadást mentés nélkül?")) {
                    this.$router.go(-1); // Geht einen Schritt im Browserverlauf zurück
                  }
    },

    async fetchRendszam() {//******************************************************************************* */
      console.log('Fetch Rendszám data');
      const postData = {
        rendszam_filter: this.rendszamFilter // Verwenden Sie v_model von Ihrem Input-Feld
      };

          console.log('postData',postData);

      try {
        let url = "/rendszam/list_rendszam.php";
        const response =  await axiosInstance.post(url, postData);

        if (response.status === 200 && response.data.data) {
          console.log('Daten erfolgreich abgerufen:', response.data.data);
          this.rendszamOptions = response.data.data;
        } else {
          console.log('Antwort nicht 200:', response);
          this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + response.statusText;
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Rendszám:', error);
        this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + error.message;
      }
    },
    selectRendszam(item){//********************************************************************************* */
      this.selectedRendszam=item.az;
      this.selectedRendszamMegn=item.mr_rendszam;
      this.selectedFuvarozoNev=item.f_nev;
      this.selectedFuvarozo=item.f_kod;

        console.log('potkocsi',item.potkocsi);

      // Überprüfen, ob item.potkocsi existiert und nicht leer ist
      if (item.potkocsi && item.potkocsi !== '') {
        this.selectedPotkocsi = item.potkocsi;
      }

    },
    async fetchSajatRendszam() {//******************************************************************************** */
      console.log('Fetch Rendszám data');
      const postData = {
        rendszam_filter: this.rendszamFilter // Verwenden Sie v_model von Ihrem Input-Feld
      };

          console.log('postData',postData);

      try {
        let url = "/rendszam/list_sajatrendszam.php";
        const response =  await axiosInstance.post(url, postData);

        if (response.status === 200 && response.data.data) {
          console.log('Daten erfolgreich abgerufen:', response.data.data);
          this.rendszamOptions = response.data.data;
        } else {
          console.log('Antwort nicht 200:', response);
          this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + response.statusText;
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Rendszám:', error);
        this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + error.message;
      }
    },
    
    selectFelsegjel(option) {
      this.selectedFelsegjel = option.value;
    },
    async fetchFuvarozo() {//******************************************************************************************* */
      console.log('Fetch Fuvarozó data');

      const postData = {
        f_cegaz:  this.ceg_az,// Verwenden Sie v_model von Ihrem Input-Feld
        f_nev_filter:this.fuvarozoFilter
      };

          console.log('postData',postData);

      try {
        let url = "/fuvarozo/list_fuvarozo.php";
        const response =  await axiosInstance.post(url, postData);

        if (response.status === 200 && response.data.data) {
          console.log('Daten erfolgreich abgerufen:', response.data.data);
          this.fuvarozoOptions = response.data.data;
        } else {
          console.log('Antwort nicht 200:', response);
          this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + response.statusText;
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Rendszám:', error);
        this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + error.message;
      }
    },
    selectFuvarozo(item){//********************************************************************************* */
      this.selectedFuvarozo=item.az;
      this.selectedFuvarozoNev=item.f_nev;
      this.selectedFuvarozoVaros=item.f_varos;

    },
    setToday(field) {
      this[field] = new Date().toISOString().substr(0, 10);
    },
    cancel() {
      this.$router.go(-1);
    },
    async mentes() {//****************************************************************************************** */
     
      try{
        let $sajat=0;
        console.log('this.selectedFuvarozo',this.selectedFuvarozo);

       let $mehet=0;

        

        if (this.selectedFuvarozo == 696){
          console.log('sajat OK');
          $sajat=-1;
        }

        let $order_csoport=this.orderData.csoport;
        console.log('order_csoport',$order_csoport);
        
        const userData = this.getUserData;

        let $user_csoport=userData.csoport;
        console.log('user_csoport',$user_csoport);
        let $user_tipus=userData.tipus;
        console.log('user_tipus',$user_tipus);
        let $order_fsz=this.orderData.fsz;
        console.log('order_fsz',$order_fsz);
        let $user_fsz=this.fsz;
        console.log('user_fsz',$user_fsz);

        if($order_fsz==$user_fsz){
          $mehet=-1;
          console.log('ugyanaz a fsz');
        }else if($user_tipus=='fuvarszervező' && $order_csoport==$user_csoport){
          $mehet=-1;
          console.log('fsz +  ua. a csoport');
        }else if($sajat==-1 && $user_tipus=='diszpécser'){
          $mehet=-1;
          console.log('diszpécser + sajat');
        }else if($user_tipus=='admin'){
          $mehet=-1;
          console.log('admin');
        }else{
          console.log('jog beállítás hiba');
        }


        if($mehet==0){
          alert('Nincs jogosultsága a művelet végrehajtásához!');
          return;
        }

        

        let status = 0; // Standardwert

          if (this.orderData.tipus == 5) {
            status = 6;  // Wenn tipus 5 ist, setze den Status auf 6
          } else if (this.orderData.tipus == 51) {
            status = 61; // Wenn tipus 51 ist, setze den Status auf 61
          }






        const postData={
          drive_az:     this.az,
          oldal:        this.oldal,
          rendsz_az:    this.selectedRendszam,
          rendszam:     this.selectedRendszamMegn,
          potkocsi:     this.selectedPotkocsi,
          felsegjel:    this.selectedFelsegjel,
          fuv_az:       this.selectedFuvarozo,
          fuvarozo:     this.selectedFuvarozoNev,
          felrakas_napja:this.felrakasDatum,
          lerakas_napja:this.lerakasDatum,
          fel_suly:     this.felvettSuly || 0,
          le_suly:      this.lerakottSuly || 0,
          rakodasi_szam:this.rakodasiSzam,
          billenes_kod:this.billenesiKod,
          status:       status,
          storno:       0,
          felh:         this.fsz,
          mod_felh:     this.fsz,
          sajat:        $sajat,
          fordulo:      1,
          megjegyzes:   this.megjegyzes,
          sorrend:      1
          
        }

        console.log('napi_mellek', postData);

        const response = await axiosInstance.post('/napi_mellek/create_napimellek.php', postData);

          console.log('Axios request completed');
          console.log('Status:', response.status);
          console.log('Status Text:', response.statusText);
          console.log('Headers:', response.headers);
          console.log('Data:', response.data);

          if (response.data.success) {
            console.log('Alles ok, die neue ID:', response.data.id);
           // alert("Az adatok mentésre kerültek.");
            // this.mentesx(); Optional, wenn Weiterleitung oder zusätzliche Logik nach dem Speichern benötigt wird
          } else {
            console.log('Fehler.');
            alert("Hiba történt, értesítse a programozót.");
          }

          console.log('Ende');
          console.log(this.valasz);

          } catch (error) {
          if (error.response) {
            console.error('Error Status:', error.response.status);
            console.error('Error Data:', error.response.data);
            console.error('Error Headers:', error.response.headers);
            alert("Hiba történt, értesítse a programozót. " + error.response.status + " " + error.response.data + " " + error.response.headers);
          } else if (error.request) {
            console.error('Keine Antwort:', error.request);
            alert("Hiba történt, értesítse a programozót." + error.request);
          } else {
            console.error('Fehler Nachricht:', error.message);
            alert("Hiba történt, értesítse a programozót. " + error.message);
          }
          console.error('Config:', error.config);
          alert("Hiba történt, értesítse a programozót. " + error.config);
          }

          console.log('Speichern Ende');

          this.$router.go(-1); // Geht einen Schritt im Browserverlauf zurück




    }
  }
};
</script>


<style>

.small-spacing {
  margin-bottom: 2px; /* Hier kannst du den Abstand anpassen */
}

.input-group, .btn-group, .dropdown {
  display: flex;
  align-items: center; /* Stellt eine genaue vertikale Zentrierung sicher */
  height: 38px; /* Setzt eine einheitliche Höhe für alle Elemente */
}

.input-group .btn-sm {
  padding: 6px 12px; /* Passt das Padding an, um Konsistenz zu gewährleisten */
  margin-left: 5px; /* Hält den Spalt zwischen den Buttons */
  margin-right: 5px;
}

.dropdown-toggle {
  padding: 6px 12px; /* Passt das Padding an, um die Höhe mit anderen Elementen zu matchen */
  margin-left: 5px;
}

.form-group.row {
  display: flex;
  align-items: center; /* Sicherstellen, dass alles in der Reihe zentriert ist */
}

.input-group-append {
  display: flex;
  align-items: center;
}

.form-group .col-md-1, .form-group .col-md-2, .form-group .col-md-3, .form-group .col-sm-3 {
  display: flex;
  align-items: center; /* Verbessert die vertikale Ausrichtung innerhalb der Spalten */
  padding-right: 0; /* Entfernt unerwünschte Padding, um Konsistenz zu gewährleisten */
}

.input-group .form-control, .input-group .btn, .input-group .dropdown-toggle {
  align-self: center; /* Zwingt jedes Element sich individuell in der Mitte auszurichten */
}

p {
  margin-bottom: 1px; /* Reduziert den Abstand unter allen <p>-Elementen */
}

</style>
