<template>
  <div>
    <!-- Navigation -->
    <navigation-bar></navigation-bar>

    <!-- Hauptinhalt -->
    <div class="container mt-4">
      <!-- Linker Bereich: Auftrag Details -->
      <div class="row">
        <div class="col-md-6 order-details text-left">
          <h4>Megrendelés adatok</h4>
          <ul v-if="megrendeles">
            <li><span>Megrendelő:</span> <strong>{{ megrendeles.megrendelo }}</strong></li>
            <li><span>Felrakó:</span> <strong>{{ megrendeles.felrako }}</strong></li>
            <li><span>Felrakó hely:</span> <strong>{{ megrendeles.felhely }}</strong></li>

            <li><span>Lerakó:</span> <strong>{{ megrendeles.lerako }}</strong></li>
            <li><span>Lerakó hely:</span> <strong>{{ megrendeles.lehely }}</strong></li>
            <li><span>Cikk:</span> <strong>{{ megrendeles.cikk }}</strong></li>
            
            <li><span>Dátum:</span> <strong>{{ megrendeles.m_datumtol }}</strong></li>
            <li><span>Szállítási határidő:</span> <strong>{{ megrendeles.szall_hatarido }}</strong></li>

            
            <li><span>Súly:</span> <strong>{{ megrendeles.m_suly }}</strong></li>
            <li><span>KM:</span> <strong>{{ megrendeles.m_km }}</strong></li>
            
            <li><span>Hivatkozási szám:</span> <strong>{{ megrendeles.hivatkozasi_szam }}</strong></li>
            
            <li><span>Megrendelői ár:</span> <strong>{{ megrendeles.m_aregysegar }}</strong></li>
            <li><span>Ártípus:</span> <strong>{{ megrendeles.m_egysegarme }}</strong></li>

            <li><span>Alapért.alv díj:</span> <strong>{{ megrendeles.mar_egysegar }}</strong></li>
            <li><span>Alv ártipus:</span> <strong>{{ megrendeles.mar_egysegarme }}</strong></li>
            
            <li><span>Megjegyzés:</span> <strong>{{ megrendeles.megjegyzes }}</strong></li>
            
            <li><span>Fuvarszervező:</span> <strong>{{ megrendeles.fuvarszervezo }}</strong></li>
            <li><span>Készítette:</span> <strong>{{ megrendeles.felh }}  ({{megrendeles.made  }})</strong></li>
            <li><span>Utolsó módosítás:</span> <strong>{{ megrendeles.mod_felh }}  ({{ megrendeles.timest }})</strong></li>
            <li><span>Csoport:</span> <strong>{{ megrendeles.csoport }}</strong></li>
          </ul>
          <p v-else>Adattöltés folyamatban...</p> <!-- Anzeige eines Ladezustands -->
        </div>

        <!-- Rechter Bereich: Buttons für Aktionen -->
        <div class="col-md-4 action-buttons">
          <button class="btn btn-success btn-block mb-2" @click="markAsComplete">Megrendelés kész</button>
          <button class="btn btn-info btn-block mb-2" @click="modifyDate">Dátum módosítása</button>
          <button class="btn btn-danger btn-block mb-2" @click="deleteOrder">Megrendelés törlése</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axiosInstance';
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      megrendeles: null
    };
  },
  computed: {
    ...mapGetters(['getorderData']),
    az() {
      return this.getorderData?.az || null;
    },
    napifo_az() {
      return this.getorderData?.napifo_az || null;
    },
  },
  mounted() {
    this.fetchOrderDetails();
    this.updateLogParams();
  },
  methods: {
    ...mapMutations(['setLogParams']),
    updateLogParams() {
    const logParams = {
      user: '',  // oder fsz, je nach Logik
      table_name: 'ftr_megrendeles',
      record_id: this.napifo_az
    };
    this.$store.commit('setLogParams', logParams);
  },
    async fetchOrderDetails() {
      const postData = {
        az: this.napifo_az
      };
      try {
        console.log('posData',postData);
        const url = "/megrendeles/egy_megrendeles.php";
        const response = await axiosInstance.post(url, postData);

        console.log(response.data);

        if (response.status === 200 && response.data.data && response.data.data.length > 0) {
          this.megrendeles = response.data.data[0];
        } else {
          console.log('Antwort enthält keine Daten oder ist nicht 200:', response);
          this.errorMessage = 'Fehler beim Abrufen der Daten: ' + response.statusText;
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
        this.errorMessage = 'Fehler beim Abrufen der Daten: ' + error.message;
      }
    },
    markAsComplete() {
      // Logik für das Markieren des Auftrags als abgeschlossen
    },
    modifyQuantity() {
      // Logik für das Ändern der Menge
    },
    modifyDate() {
      // Logik für das Ändern des Datums
    },
    deleteOrder() {
      // Logik für das Löschen des Auftrags
    }
  }
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}

.order-details ul {
  list-style-type: none;
  padding: 0;
}

.order-details ul li {
  margin-bottom: 10px;
}

.action-buttons .btn {
  width: 80%; /* Breite der Buttons auf 80% reduzieren */
  padding: 10px;
  font-size: 16px;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Buttons nicht so breit machen */
}
</style>
