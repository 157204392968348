import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'; // Importieren Sie Ihren Vuex Store

//import HomeView from '../views/HomeView.vue'
import LoginForm from '../views/LoginForm.vue'; 
import MenuForm from '../views/MenuForm.vue'; // Menü-Komponente
import NewOrder from '../views/newOrder.vue'; // Neue Bestellung Komponente
import osszesMegrendeles from '../views/osszesMegrendeles.vue'; // Neue Bestellung Komponente
import passwordChange from '../views/passwordChange.vue';
import newTruck from '../views/newTruck.vue';
import orderDetails from '../views/orderDetails.vue'; 
import HelpPage from '@/views/HelpPage.vue';
import ChangeLog from '@/views/ChangeLog.vue'; // Pfad zur ChangeLog-Komponente


const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginForm
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuForm
  },
  {
    path: '/new_order',
    name: 'new_order',
     component: NewOrder,
    //component: () => import('@/views/NewOrder.vue'),
    meta: { requiresAuth: true } // Meta-Feld, um die Authentifizierung zu markieren
  },
  {
    path: '/osszesMegrendeles',
    name: 'osszesMegrendeles',
    component: osszesMegrendeles
  },
  {
    path: '/passwordChange',
    name: 'passwordChange',
    component: passwordChange
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    component: orderDetails
  },
  {
    path: '/newTruck',
    name: 'newTruck',
    component: newTruck
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/help',
    name: 'Help',
    component: HelpPage
  },
  {
    path: '/change-log',
    name: 'ChangeLog',
    component: ChangeLog
  }
  
];



const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// Navigation Guard zur Überprüfung der Berechtigungen
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Prüfen Sie, ob der Benutzer als Fuvarszervező authentifiziert ist
    if (store.state.userData.tipus === 'fuvarszervező') {
      next();
    } else {
      alert('Nincs jogosultsága új megrendelés rögzítéséhez');
      next(false); // Verhindert den Zugriff, wenn die Bedingung nicht erfüllt ist
    }
  } else {
    next(); // Erlaubt den Zugang, wenn keine speziellen Berechtigungen benötigt werden
  }
});


export default router
