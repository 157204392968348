<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <form @submit.prevent="changePassword">
          <h1 class="text-center">Az első bejelenkezésnél a jelszót módosítani kell.</h1>
          <div class="form-group">
            <label for="newPassword">Új jelszó</label>
            <input type="password" v-model="newPassword" id="newPassword" class="form-control" required />
          </div>
          <div class="form-group">
            <label for="confirmPassword">Ismételt megadása</label>
            <input type="password" v-model="confirmPassword" id="confirmPassword" class="form-control" required />
          </div>
          <button type="submit" class="btn btn-primary btn-block">Jelszó módosítása</button>
        </form>
        <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import axiosInstance from '@/axiosInstance';
  import { mapState } from 'vuex';
  
  export default {
    data() {
      return {
        newPassword: '',
        confirmPassword: '',
        errorMessage: '',
        isChangingPassword: false
      };
    },
    computed: {
      ...mapState({
        szemAz: state => state.userData.szem_az
      })
    },
    methods: {
      async changePassword() {
        this.errorMessage = '';
        if (this.newPassword !== this.confirmPassword) {
          this.errorMessage = 'A beírt ellenőrző jelszó hibás!';
          return;
        }
  
        this.isChangingPassword = true;
  
        try {
          const response = await axiosInstance.post('/ftr/change_password.php', {
            szem_az: this.szemAz,
            newPassword: this.newPassword
          });
  
          if (response.data.status === 'success') {
            alert('Jelszó modosítva.');
            this.newPassword = '';
            this.confirmPassword = '';
            this.$router.push({ name: 'menu' });
          } else {
            this.errorMessage = 'Hiba a jelszó módosításnál: ' + response.data.message;
          }
        } catch (error) {
          this.errorMessage = 'Hiba történt: ' + error.message;
        } finally {
          this.isChangingPassword = false;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .form-group {
    margin-bottom: 15px;
  }
  </style>
  