import { createStore } from 'vuex'

// Enum für Benutzertypen
export const UserTypes = Object.freeze({
  ADMIN: 'admin',
  FUVAR_SZERVEZŐ: 'fuvarszervező',
  DISZPÉCSER: 'diszpécser'
});


export default createStore({
  state: {
    userData: {  
      szem_az: '',
      nev: '',
      fsz: '',
      ceg_az: '',
      first_sign: '',
      tipus: '',
      csoport: ''
    },
    order: {
      az: null,
      cegaz: null,
      datum: null,
      viszonylat: null,
      napifo_az: null,
      napimellek_az: null,
      tipus: null,
      megrendelo: null,
      cikk: null,
      szall_hatarido: null,
      hivatkozasi_szam: null,
      oldal:null,
      fo_megjegyzes:null,
      csoport:null,
      fsz:null
    },
    logParams: {  // Neue State für die ChangeLog-Parameter
      user: '',
      table_name: '',
      record_id: 0
    }
  },
  getters: {
    getUserData: state => state.userData,
    getorderData: state => state.order,
    getUserType: state => state.userData.tipus,  // Getter für den Benutzertyp
    isAdmin: state => state.userData.tipus === UserTypes.ADMIN,  // Prüfen, ob der Benutzer ein Admin ist
    isFuvarszervezo: state => state.userData.tipus === UserTypes.FUVAR_SZERVEZŐ,  // Prüfen, ob der Benutzer ein Fuvarszervező ist
    isDiszpecser: state => state.userData.tipus === UserTypes.DISZPÉCSER,  // Prüfen, ob der Benutzer ein Diszpécser ist
    getLogParams: state => state.logParams  // Getter für die ChangeLog-Parameter
  },
  mutations: {
    setUserData(state, userData) {
      state.userData.szem_az = userData.szem_az;
      state.userData.nev = userData.nev;
      state.userData.fsz = userData.fsz;
      state.userData.ceg_az = userData.ceg_az;
      state.userData.first_sign = userData.firstSign;
      state.userData.tipus = userData.tipus;
      state.userData.csoport = userData.csoport;
    },
    setorderData(state, orderData) {
      state.order = orderData;
      console.log('orderData', orderData);
    },
    setLogParams(state, logParams) {  // Neue Mutation zum Setzen der ChangeLog-Parameter
      state.logParams = logParams;
    }
  },
  actions: {
    // Du kannst hier weitere Actions hinzufügen, falls notwendig
  },
  modules: {
    // Falls du dein Store in Module unterteilen möchtest
  }


})
