<template>
  <div class="container">
    <div class="d-flex justify-content-center align-items-center vh-100">
      <div class="col-lg-6 col-md-8">
        <div class="anmelde-container">
          <img src="../assets/bogos.png" alt="Céges logo" class="logo mb-4">
          <h1 class="text-center mb-4">Üdvözöljük a Bogos-Trans Kft FTR oldalán.</h1>
          <p class="text-center">Kérjük jelentkezzen be a használathoz.</p>

          <!-- Anmeldeformular -->
          <form @submit.prevent="login">
            <div class="form-group">
              <label for="email">Név</label>
              <input type="email" class="form-control" id="email" v-model="email" required>
            </div>
            <div class="form-group">
              <label for="password">Jelszó</label>
              <input type="password" class="form-control" id="password" v-model="password" required>
            </div>
            <button type="submit" class="btn btn-primary btn-block" :disabled="isLoggingIn">Bejelentkezés</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axiosInstance';
import { mapMutations } from 'vuex';

export default {
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: '',
      isLoggingIn: false
    };
  },
  methods: {
    ...mapMutations(['setUserData']),
    login() {
      this.isLoggingIn = true;
      const loginData = {
        email: this.email,
        password: this.password
      };

      console.log('indul az axios rész...');

      axiosInstance.post('/ftr/ftr_user_check.php', loginData)
      .then(response => {
        if(response.data.status === 'success') {
          console.log('API response', response.data);
          // Weitere Verarbeitung bei erfolgreichem Login

          this.setUserData({
            szem_az: response.data.szem_az,
            nev: response.data.nev,
            fsz: response.data.fsz,
            ceg_az: response.data.ceg_az,
            first_sign: response.data.first_sign,
            tipus: response.data.tipus,
            csoport: response.data.csoport
          });

          if (response.data.first_sign == -1) {
            this.$router.push({ name: 'passwordChange' });
          } else if (response.data.first_sign == 0) {
            this.$router.push('/menu');
            

          }
        } else {
          console.log('nem succes');
          alert('Hiba a bejelentkezés során: ' + response.data.message);
        }
      })
      .catch(error => {
        console.error('API-hiba:', error);
        alert('Hiba történt a bejelentkezés során, kérem ismételje meg a bejelentkezés egy kis idő múlva');
      })
      .finally(() => {
        this.isLoggingIn = false;
      });
    }
  }
};
</script>

<style scoped>
.anmelde-container {
  padding: 2em;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.form-group {
  margin-bottom: 1em;
}

.form-group label {
  margin-bottom: 0.5em;
  display: block;
}

.form-group input {
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
}

.btn {
  width: 100%;
  padding: 0.75em;
}


.logo {
  display: block;
  margin: 0 auto 1em;
  max-width: 100%;
  height: auto;
  width: 200px; /* Füge diese Zeile hinzu, um die maximale Breite des Logos festzulegen */
}

</style>
